import { PrismicRichText, PrismicImage, PrismicPageDefinition } from "../../utils/queries/types";

export type BackgroundColor = "color-dark-blue" | "color-black" | "color-blue" | "color-white" | "pattern-dark" | "pattern-light";
export type TextColor = "blue" | "white" | "black" | "light-blue" | "dark-blue";
export type HeaderSize = 'default' | 'large' | 'large-1x' | 'large-2x';
export type LayoutPosition = 'left' | 'right';
export type ColLayoutConfig = '50/50' | '25/75' | '75/25';
export type ColItems = '2' | '3' | '4';

// # Image can link to either a page definition, or a landing page, or a blog post
export type ImageLink = PrismicPageDefinition | {
    type: 'blog_post';
    uid: string;
} | {
    type: 'landing_page';
    uid: string;
}

export const convert_image_link = (image_link: ImageLink): PrismicPageDefinition => {
    if (image_link && image_link.type) {
        if (image_link.type == "blog_post") {
            return {
                document: {
                    data: {
                        is_external: false,
                        url: `/blog/post/${image_link.uid}`
                    }
                }
            };
        } else if (image_link.type == "landing_page") {
            return {
                document: {
                    data: {
                        is_external: false,
                        url: `/${image_link.uid}`
                    }
                }
            };
        }
    }
    if (!image_link.document) {
        return { document: { data: image_link } };
    }
    return (image_link as PrismicPageDefinition);
}

export type TypeHeaderType = 'header';
export type TypeResuableHeader = {
    slice_type: TypeHeaderType;
    primary: {
        header: string;
        preHeader: string;
        subheader: PrismicRichText;
        text_color: TextColor;
        background: BackgroundColor;
    }
}

export type TypeContentType = 'content';
export type TypeResuableContent = {
    slice_type: TypeContentType;
    primary: {
        heading: PrismicRichText;
        content: PrismicRichText;
        text_color: TextColor;
        header_color: TextColor;
        header_size: HeaderSize;
        background: BackgroundColor;
        show_header_separator: boolean;
        extra_bottom_spacing: boolean;
        text_align: 'center' | 'left' | 'right';
    }
}

export type TypeRawHtmlType = 'raw_html';
export type TypeResuableRawHtml = {
    slice_type: TypeRawHtmlType;
    primary: {
        content: PrismicRichText;
        text_color: TextColor;
        background: BackgroundColor;
        text_align: 'center' | 'left' | 'right';
        html_content: PrismicRichText;
        margin: boolean;
    }
}

export type TypeImageLeftTextRight = 'image_left___text_right';
export type TypeReusableImageLeftTextRight = {
    slice_type: TypeImageLeftTextRight;
    primary: {
        header_size: HeaderSize;
        header_color: TextColor;
        text_color: TextColor;
        background: BackgroundColor;
        image: PrismicImage;
        content: PrismicRichText;
        right_side_read_more_link_text: string;
        read_more_link_to: PrismicPageDefinition;
        read_more_link_colors: boolean;
        open_read_more_new_tab: boolean;
        image_link: ImageLink;
        image_link_opens_in_new_tab: boolean;
    };
}

export type TypeImageText = 'image_and_text';
export type TypeReusableImageText = {
    slice_type: TypeImageText;
    primary: {
        cta_bg_color: TextColor;
        cta_image: PrismicImage;
        cta_image_position: LayoutPosition;
        cta_text: PrismicRichText;
        layout: ColLayoutConfig;
    };
}

export type TypeFactAuthor = 'technology_facts_with_author';
export type TypeReusableFactAuthor = {
    slice_type: TypeFactAuthor;
    primary: {
        introduction: PrismicRichText;
        quote_image: PrismicImage;
        quote_subtext: PrismicRichText;
        quote_text: PrismicRichText;
        title: PrismicRichText;
        bg_color: TextColor;
        col_layout: ColItems;
    }
    items: {
        fact_image: PrismicImage;
        fact_text: PrismicRichText;
    }[]
}


export type TypeImageRightTextLeft = 'image_right___text_left';
export type TypeReusableImageRightTextLeft = {
    slice_type: TypeImageRightTextLeft;
    primary: {
        header_size: HeaderSize;
        header_color: TextColor;
        text_color: TextColor;
        background: BackgroundColor;
        image: PrismicImage;
        content: PrismicRichText;
        left_side_read_more_link_text: string;
        read_more_link_to: PrismicPageDefinition;
        read_more_link_colors: boolean;
        open_read_more_new_tab: boolean;
        image_link: ImageLink;
        image_link_opens_in_new_tab: boolean;
    };
}

export type TypeIconGallery = 'icon_gallery';
export type TypeReusableIconGalleryItem = {
    details: PrismicRichText;
    icon: PrismicImage;
    title: string;
    image_link: ImageLink;
};
export type TypeReusableIconGallery = {
    slice_type: TypeIconGallery;
    primary: {
        header: string;
        image_sizing: 'small' | 'medium' | 'large';
        header_size: HeaderSize;
        header_color: TextColor;
        text_color: TextColor;
        background: BackgroundColor;
    };
    items: TypeReusableIconGalleryItem[];
}

export type TypeContentLeftContactFormRight = 'content_left___contact_form_right';
export type TypeResuableContentLeftContactFormRight = {
    slice_type: TypeContentLeftContactFormRight;
    primary: {
        header_size: HeaderSize;
        left_side: PrismicRichText;
        header_color: TextColor;
        text_color: TextColor;
        background: BackgroundColor;
        vertical_alig: 'top' | 'center';
        contact_form_pixel: string;
    };
}

export type TypeContentLeftHubspotFormRight = 'content___hubspot';
export type TypeResuableContentLeftHubspotFormRight = {
    slice_type: TypeContentLeftContactFormRight;
    primary: {
        header_size: HeaderSize;
        left_side: PrismicRichText;
        header_color: TextColor;
        text_color: TextColor;
        background: BackgroundColor;
        vertical_alig: 'top' | 'center';
        portal_id: string;
        form_id: string;
    };
}

export type TypeCompetitorTable = 'competitor_table';
export type TypeResuableCompetitorTable = {
    slice_type: TypeCompetitorTable;
    primary: {
        table: {
            document: {
                data: {
                    amazee_label: string;
                    checked_image: PrismicImage;
                    unchecked_image: PrismicImage;
                    competitor_1_name: string;
                    competitor_2_name: string;
                    competitor_3_name: string;
                    striped_rows: boolean;
                    rows: Array<{
                        "feature": string;
                        "competitor3": boolean;
                        "competitor2": boolean;
                        "competitor1": boolean;
                        "amazee": boolean;
                    }>;
                }
            }
        }
        header_color: TextColor;
        background: BackgroundColor;
        header: PrismicRichText;
    };
}

export type TypeReusableType = TypeHeaderType | TypeContentType | TypeImageLeftTextRight | TypeImageRightTextLeft | TypeIconGallery | TypeContentLeftContactFormRight | TypeContentLeftHubspotFormRight | TypeRawHtmlType | TypeCompetitorTable | TypeImageText;
export type TypeAnyComponent = TypeResuableHeader | TypeResuableContentLeftContactFormRight | TypeReusableIconGallery | TypeReusableImageRightTextLeft | TypeResuableContent | TypeReusableImageLeftTextRight | TypeResuableContentLeftHubspotFormRight | TypeResuableRawHtml | TypeResuableCompetitorTable | TypeReusableImageText;
export type TypeResuableBody = Array<TypeAnyComponent>;
